globalThis["__sentryRewritesTunnelPath__"] = "/api/error";
globalThis["SENTRY_RELEASE"] = {"id":"6wGhKOrWWxB04NPFxVxKI"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from "@sentry/nextjs";
import { beforeSend } from "./sentryShared";

Sentry.init({
  dsn:
    process.env.NODE_ENV === "production"
      ? process.env.NEXT_PUBLIC_SENTRY_DSN
      : "",
  tracesSampleRate: 1,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
      networkDetailAllowUrls: [window.location.origin],
    }),
    Sentry.browserTracingIntegration(),
  ],
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0,
  tunnel: "/api/error",
  normalizeDepth: 10,
  profilesSampleRate: 1.0,
  beforeSend,
});
